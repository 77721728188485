// Generated with response data from https://www.googleapis.com/webfonts/v1/webfonts
// see: https://developers.google.com/fonts/docs/developer_api
const detail = {
  Roboto: {
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Open Sans': {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
    category: 'sans-serif',
  },
  'Noto Sans JP': {
    variants: ['100', '300', 'regular', '500', '700', '900'],
    category: 'sans-serif',
  },
  Lato: {
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '700',
      '700italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Montserrat: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Roboto Condensed': {
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Poppins: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Source Sans Pro': {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Oswald: {
    variants: ['200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Roboto Mono': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
    ],
    category: 'monospace',
  },
  'Noto Sans': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Raleway: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Ubuntu: {
    variants: ['300', '300italic', 'regular', 'italic', '500', '500italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'PT Sans': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Merriweather: {
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic', '900', '900italic'],
    category: 'serif',
  },
  Nunito: {
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Roboto Slab': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Playfair Display': {
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  Inter: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans KR': {
    variants: ['100', '300', 'regular', '500', '700', '900'],
    category: 'sans-serif',
  },
  Rubik: {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Open Sans Condensed': {
    variants: ['300', '300italic', '700'],
    category: 'sans-serif',
  },
  Lora: {
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    category: 'serif',
  },
  Mukta: {
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  'Nunito Sans': {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Work Sans': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Fira Sans': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'PT Serif': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'Nanum Gothic': {
    variants: ['regular', '700', '800'],
    category: 'sans-serif',
  },
  Quicksand: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Hind Siliguri': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Titillium Web': {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '900',
    ],
    category: 'sans-serif',
  },
  'Noto Sans TC': {
    variants: ['100', '300', 'regular', '500', '700', '900'],
    category: 'sans-serif',
  },
  'Noto Serif': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  Inconsolata: {
    variants: ['200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'monospace',
  },
  Barlow: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Karla: {
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
    category: 'sans-serif',
  },
  Heebo: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Libre Franklin': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Josefin Sans': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
    ],
    category: 'sans-serif',
  },
  Arimo: {
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    category: 'sans-serif',
  },
  Oxygen: {
    variants: ['300', 'regular', '700'],
    category: 'sans-serif',
  },
  'PT Sans Narrow': {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  'Libre Baskerville': {
    variants: ['regular', 'italic', '700'],
    category: 'serif',
  },
  Dosis: {
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  'IBM Plex Sans': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'sans-serif',
  },
  'Source Code Pro': {
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'monospace',
  },
  Mulish: {
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'DM Sans': {
    variants: ['regular', 'italic', '500', '500italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'Source Serif Pro': {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '900',
      '900italic',
    ],
    category: 'serif',
  },
  Bitter: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  'EB Garamond': {
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
    category: 'serif',
  },
  Cabin: {
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    category: 'sans-serif',
  },
  'Noto Sans SC': {
    variants: ['100', '300', 'regular', '500', '700', '900'],
    category: 'sans-serif',
  },
  Anton: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Varela Round': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Dancing Script': {
    variants: ['regular', '500', '600', '700'],
    category: 'handwriting',
  },
  Kanit: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Prompt: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Cairo: {
    variants: ['200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Barlow Condensed': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Abel: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Hind: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Fjalla One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Exo 2': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Overpass: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Arvo: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'Yanone Kaffeesatz': {
    variants: ['200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Maven Pro': {
    variants: ['regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Pacifico: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Sans HK': {
    variants: ['100', '300', 'regular', '500', '700', '900'],
    category: 'sans-serif',
  },
  Manrope: {
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  'Merriweather Sans': {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
    category: 'sans-serif',
  },
  Teko: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Architects Daughter': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Shadows Into Light': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Signika Negative': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  Archivo: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Asap: {
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    category: 'sans-serif',
  },
  'Indie Flower': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'IBM Plex Serif': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'serif',
  },
  'Hind Madurai': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Slabo 27px': {
    variants: ['regular'],
    category: 'serif',
  },
  'Fira Sans Condensed': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Saira Condensed': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Cormorant Garamond': {
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'serif',
  },
  Assistant: {
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  'Permanent Marker': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Serif JP': {
    variants: ['200', '300', 'regular', '500', '600', '700', '900'],
    category: 'serif',
  },
  'Ubuntu Mono': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'monospace',
  },
  'Patrick Hand': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Secular One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Questrial: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Catamaran: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Caveat: {
    variants: ['regular', '500', '600', '700'],
    category: 'handwriting',
  },
  Domine: {
    variants: ['regular', '500', '600', '700'],
    category: 'serif',
  },
  Exo: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Rajdhani: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Red Hat Display': {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Public Sans': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Zilla Slab': {
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'serif',
  },
  'Archivo Narrow': {
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    category: 'sans-serif',
  },
  Satisfy: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Barlow Semi Condensed': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'M PLUS Rounded 1c': {
    variants: ['100', '300', 'regular', '500', '700', '800', '900'],
    category: 'sans-serif',
  },
  Jost: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Signika: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  Play: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  ABeeZee: {
    variants: ['regular', 'italic'],
    category: 'sans-serif',
  },
  Acme: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Tajawal: {
    variants: ['200', '300', 'regular', '500', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Display': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Cookie: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Amatic SC': {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  'Bree Serif': {
    variants: ['regular'],
    category: 'serif',
  },
  Martel: {
    variants: ['200', '300', 'regular', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Space Mono': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'monospace',
  },
  'Space Grotesk': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  Vollkorn: {
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  'Nanum Myeongjo': {
    variants: ['regular', '700', '800'],
    category: 'serif',
  },
  Spartan: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'M PLUS 1p': {
    variants: ['100', '300', 'regular', '500', '700', '800', '900'],
    category: 'sans-serif',
  },
  Padauk: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Sarabun: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
    ],
    category: 'sans-serif',
  },
  Yantramanav: {
    variants: ['100', '300', 'regular', '500', '700', '900'],
    category: 'sans-serif',
  },
  Cinzel: {
    variants: ['regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Chakra Petch': {
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'sans-serif',
  },
  Alegreya: {
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  'Great Vibes': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Alegreya Sans': {
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Amiri: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'Crete Round': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  'Gothic A1': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Archivo Black': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Antic Slab': {
    variants: ['regular'],
    category: 'serif',
  },
  'IBM Plex Mono': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'monospace',
  },
  Courgette: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Tinos: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'Ubuntu Condensed': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'PT Sans Caption': {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  'Russo One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Didact Gothic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Old Standard TT': {
    variants: ['regular', 'italic', '700'],
    category: 'serif',
  },
  'Frank Ruhl Libre': {
    variants: ['300', 'regular', '500', '700', '900'],
    category: 'serif',
  },
  Cardo: {
    variants: ['regular', 'italic', '700'],
    category: 'serif',
  },
  'DM Serif Display': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  Kalam: {
    variants: ['300', 'regular', '700'],
    category: 'handwriting',
  },
  'Kaushan Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noticia Text': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'Paytone One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Asap Condensed': {
    variants: ['regular', 'italic', '500', '500italic', '600', '600italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Cuprum: {
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    category: 'sans-serif',
  },
  Prata: {
    variants: ['regular'],
    category: 'serif',
  },
  'Francois One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Orbitron: {
    variants: ['regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Parisienne: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Playfair Display SC': {
    variants: ['regular', 'italic', '700', '700italic', '900', '900italic'],
    category: 'serif',
  },
  Spectral: {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
    ],
    category: 'serif',
  },
  Almarai: {
    variants: ['300', 'regular', '700', '800'],
    category: 'sans-serif',
  },
  Chivo: {
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic', '900', '900italic'],
    category: 'sans-serif',
  },
  Changa: {
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  Gelasio: {
    variants: ['regular', 'italic', '500', '500italic', '600', '600italic', '700', '700italic'],
    category: 'serif',
  },
  Sacramento: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Encode Sans': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Rokkitt: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Gloria Hallelujah': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Eczar: {
    variants: ['regular', '500', '600', '700', '800'],
    category: 'serif',
  },
  'El Messiri': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Quattrocento Sans': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Faustina: {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
    category: 'serif',
  },
  'Noto Serif TC': {
    variants: ['200', '300', 'regular', '500', '600', '700', '900'],
    category: 'serif',
  },
  Khand: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Montserrat Alternates': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Cormorant: {
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'serif',
  },
  'Noto Kufi Arabic': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Cantarell: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Volkhov: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'Pathway Gothic One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Sawarabi Mincho': {
    variants: ['regular'],
    category: 'serif',
  },
  Alata: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Ropa Sans': {
    variants: ['regular', 'italic'],
    category: 'sans-serif',
  },
  'PT Mono': {
    variants: ['regular'],
    category: 'monospace',
  },
  'News Cycle': {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Quattrocento: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  Saira: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Josefin Slab': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
    ],
    category: 'serif',
  },
  Unna: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  Yellowtail: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Vidaloka: {
    variants: ['regular'],
    category: 'serif',
  },
  Itim: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Outfit: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Sanchez: {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  Ultra: {
    variants: ['regular'],
    category: 'serif',
  },
  'Advent Pro': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  Mitr: {
    variants: ['200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  Handlee: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Viga: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Fira Sans Extra Condensed': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Philosopher: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Neuton: {
    variants: ['200', '300', 'regular', 'italic', '700', '800'],
    category: 'serif',
  },
  Lusitana: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  Marcellus: {
    variants: ['regular'],
    category: 'serif',
  },
  'Lexend Deca': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Monda: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Aleo: {
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'DM Serif Text': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  'Bai Jamjuree': {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'sans-serif',
  },
  Tangerine: {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  Sora: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  Merienda: {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  Gudea: {
    variants: ['regular', 'italic', '700'],
    category: 'sans-serif',
  },
  Mali: {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'handwriting',
  },
  Taviraj: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'serif',
  },
  Karma: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'serif',
  },
  'Noto Serif KR': {
    variants: ['200', '300', 'regular', '500', '600', '700', '900'],
    category: 'serif',
  },
  'Sawarabi Gothic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Alice: {
    variants: ['regular'],
    category: 'serif',
  },
  Ruda: {
    variants: ['regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Pragati Narrow': {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  'Saira Semi Condensed': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Hammersmith One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Hind Vadodara': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Istok Web': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Actor: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Neucha: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Adamina: {
    variants: ['regular'],
    category: 'serif',
  },
  Allura: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Arapey: {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  'Nanum Gothic Coding': {
    variants: ['regular', '700'],
    category: 'monospace',
  },
  Jura: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Cabin Condensed': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Gochi Hand': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Amaranth: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Lexend: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Khula: {
    variants: ['300', 'regular', '600', '700', '800'],
    category: 'sans-serif',
  },
  Armata: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Homemade Apple': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Serif SC': {
    variants: ['200', '300', 'regular', '500', '600', '700', '900'],
    category: 'serif',
  },
  Palanquin: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  Pangolin: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'IBM Plex Sans Condensed': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'sans-serif',
  },
  'Abhaya Libre': {
    variants: ['regular', '500', '600', '700', '800'],
    category: 'serif',
  },
  'Rock Salt': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Gentium Basic': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'Mukta Malar': {
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  'Rubik Mono One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Sen: {
    variants: ['regular', '700', '800'],
    category: 'sans-serif',
  },
  BenchNine: {
    variants: ['300', 'regular', '700'],
    category: 'sans-serif',
  },
  Economica: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'Crimson Pro': {
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  'Cantata One': {
    variants: ['regular'],
    category: 'serif',
  },
  'Share Tech Mono': {
    variants: ['regular'],
    category: 'monospace',
  },
  'Marck Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Syncopate: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Quantico: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'Bodoni Moda': {
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  'Kosugi Maru': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Alegreya Sans SC': {
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'IM Fell English SC': {
    variants: ['regular'],
    category: 'serif',
  },
  'Gentium Book Basic': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  Alef: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Cousine: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'monospace',
  },
  'Courier Prime': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'monospace',
  },
  'Alex Brush': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Nothing You Could Do': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Sriracha: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Julius Sans One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Bad Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Pontano Sans': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Tenor Sans': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Sarala: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  'Red Hat Text': {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
    ],
    category: 'sans-serif',
  },
  Mada: {
    variants: ['200', '300', 'regular', '500', '600', '700', '900'],
    category: 'sans-serif',
  },
  'Nanum Pen Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Arsenal: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Electrolize: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Ramabhadra: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Sorts Mill Goudy': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  'Fira Mono': {
    variants: ['regular', '500', '700'],
    category: 'monospace',
  },
  'Martel Sans': {
    variants: ['200', '300', 'regular', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Jaldi: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Damion: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Allerta: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Laila: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  Varela: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Rufina: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  'Mr Dafoe': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Rasa: {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
    ],
    category: 'serif',
  },
  'Noto Naskh Arabic': {
    variants: ['regular', '500', '600', '700'],
    category: 'serif',
  },
  Georama: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Encode Sans Condensed': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Caveat Brush': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Italianno: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Charm: {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  Niramit: {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'sans-serif',
  },
  'Shadows Into Light Two': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Reenie Beanie': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Basic: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Tamil': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Yrsa: {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
    ],
    category: 'serif',
  },
  Krub: {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'sans-serif',
  },
  Literata: {
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  Commissioner: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Aclonica: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Sintony: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Baskervville: {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  Sansita: {
    variants: ['regular', 'italic', '700', '700italic', '800', '800italic', '900', '900italic'],
    category: 'sans-serif',
  },
  'Black Han Sans': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Six Caps': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Holtwood One SC': {
    variants: ['regular'],
    category: 'serif',
  },
  Blinker: {
    variants: ['100', '200', '300', 'regular', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Kameron: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  Belgrano: {
    variants: ['regular'],
    category: 'serif',
  },
  Spinnaker: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Kreon: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'serif',
  },
  Voltaire: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Glegoo: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  'Anonymous Pro': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'monospace',
  },
  'Pinyon Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Reem Kufi': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Darker Grotesque': {
    variants: ['300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Candal: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Hind Guntur': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  Mate: {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  'Palanquin Dark': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Annie Use Your Telescope': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Rancho: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Pridi: {
    variants: ['200', '300', 'regular', '500', '600', '700'],
    category: 'serif',
  },
  'Days One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Mate SC': {
    variants: ['regular'],
    category: 'serif',
  },
  'Markazi Text': {
    variants: ['regular', '500', '600', '700'],
    category: 'serif',
  },
  'PT Serif Caption': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  Average: {
    variants: ['regular'],
    category: 'serif',
  },
  'Nanum Brush Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Enriqueta: {
    variants: ['regular', '500', '600', '700'],
    category: 'serif',
  },
  'Allerta Stencil': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Miriam Libre': {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  'Suez One': {
    variants: ['regular'],
    category: 'serif',
  },
  'Mukta Vaani': {
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  'Covered By Your Grace': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Cutive Mono': {
    variants: ['regular'],
    category: 'monospace',
  },
  Alatsi: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Judson: {
    variants: ['regular', 'italic', '700'],
    category: 'serif',
  },
  'Berkshire Swash': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Michroma: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Niconne: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'GFS Didot': {
    variants: ['regular'],
    category: 'serif',
  },
  'Just Another Hand': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Lateef: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Antic: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Rozha One': {
    variants: ['regular'],
    category: 'serif',
  },
  Rambla: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'Coming Soon': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Telex: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Source Sans 3': {
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Castoro: {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  'Short Stack': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Epilogue: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Fira Code': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'monospace',
  },
  Aldrich: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Thai': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Mrs Saint Delafield': {
    variants: ['regular'],
    category: 'handwriting',
  },
  VT323: {
    variants: ['regular'],
    category: 'monospace',
  },
  'Herr Von Muellerhoff': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Gilda Display': {
    variants: ['regular'],
    category: 'serif',
  },
  'Leckerli One': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Caudex: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'Overpass Mono': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'monospace',
  },
  Athiti: {
    variants: ['200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Saira Extra Condensed': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Be Vietnam Pro': {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Norican: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Atkinson Hyperlegible': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Amethysta: {
    variants: ['regular'],
    category: 'serif',
  },
  Biryani: {
    variants: ['200', '300', 'regular', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Arizonia: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Comic Neue': {
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
    category: 'handwriting',
  },
  Amita: {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  K2D: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
    ],
    category: 'sans-serif',
  },
  Trocchi: {
    variants: ['regular'],
    category: 'serif',
  },
  Jua: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Rochester: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Marcellus SC': {
    variants: ['regular'],
    category: 'serif',
  },
  'Alegreya SC': {
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'serif',
  },
  Capriola: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Alike: {
    variants: ['regular'],
    category: 'serif',
  },
  Yesteryear: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Arbutus Slab': {
    variants: ['regular'],
    category: 'serif',
  },
  'Libre Caslon Text': {
    variants: ['regular', 'italic', '700'],
    category: 'serif',
  },
  Scada: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Copse: {
    variants: ['regular'],
    category: 'serif',
  },
  'Average Sans': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Urbanist: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Jockey One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Delius: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Oranienbaum: {
    variants: ['regular'],
    category: 'serif',
  },
  Cambay: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Magra: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Lustria: {
    variants: ['regular'],
    category: 'serif',
  },
  Coustard: {
    variants: ['regular', '900'],
    category: 'serif',
  },
  Mandali: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Alike Angular': {
    variants: ['regular'],
    category: 'serif',
  },
  'JetBrains Mono': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
    category: 'monospace',
  },
  Kristi: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Coda Caption': {
    variants: ['800'],
    category: 'sans-serif',
  },
  Nobile: {
    variants: ['regular', 'italic', '500', '500italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'Krona One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Fauna One': {
    variants: ['regular'],
    category: 'serif',
  },
  Pattaya: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'STIX Two Text': {
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    category: 'serif',
  },
  Syne: {
    variants: ['regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  Belleza: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Petit Formal Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Carme: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  NTR: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Trirong: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'serif',
  },
  Podkova: {
    variants: ['regular', '500', '600', '700', '800'],
    category: 'serif',
  },
  'La Belle Aurore': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Quintessential: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Fraunces: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  'Comforter Brush': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Serif Display': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  Suranna: {
    variants: ['regular'],
    category: 'serif',
  },
  Esteban: {
    variants: ['regular'],
    category: 'serif',
  },
  Halant: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'serif',
  },
  'Kumbh Sans': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Grand Hotel': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Cedarville Cursive': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Bellefair: {
    variants: ['regular'],
    category: 'serif',
  },
  'Cormorant Infant': {
    variants: [
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'serif',
  },
  Sunflower: {
    variants: ['300', '500', '700'],
    category: 'sans-serif',
  },
  Aladin: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Qwigley: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Molengo: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Merienda One': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Maitree: {
    variants: ['200', '300', 'regular', '500', '600', '700'],
    category: 'serif',
  },
  'Slabo 13px': {
    variants: ['regular'],
    category: 'serif',
  },
  Ovo: {
    variants: ['regular'],
    category: 'serif',
  },
  Hanuman: {
    variants: ['100', '300', 'regular', '700', '900'],
    category: 'serif',
  },
  'Do Hyeon': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Devanagari': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Kite One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Brawler: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  Rosario: {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
    ],
    category: 'sans-serif',
  },
  'Carrois Gothic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Hi Melody': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Calligraffitti: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Amiko: {
    variants: ['regular', '600', '700'],
    category: 'sans-serif',
  },
  Kosugi: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Malayalam': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Marmelad: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Oxygen Mono': {
    variants: ['regular'],
    category: 'monospace',
  },
  Marvel: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'Duru Sans': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Redressed: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Sans Telugu': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Spectral SC': {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
    ],
    category: 'serif',
  },
  Sofia: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Thasadith: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'IM Fell English': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  BioRhyme: {
    variants: ['200', '300', 'regular', '700', '800'],
    category: 'serif',
  },
  'Hepta Slab': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Sans Arabic': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Monsieur La Doulaise': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Radley: {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  Schoolbell: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Quando: {
    variants: ['regular'],
    category: 'serif',
  },
  'Dawning of a New Day': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Convergence: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Shippori Mincho': {
    variants: ['regular', '500', '600', '700', '800'],
    category: 'serif',
  },
  'Encode Sans Semi Condensed': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Antonio: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  Homenaje: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Mandaic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Nova Mono': {
    variants: ['regular'],
    category: 'monospace',
  },
  'Mr De Haviland': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Metrophobic: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Manjari: {
    variants: ['100', 'regular', '700'],
    category: 'sans-serif',
  },
  Livvic: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'IM Fell DW Pica': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  Cutive: {
    variants: ['regular'],
    category: 'serif',
  },
  Bentham: {
    variants: ['regular'],
    category: 'serif',
  },
  Harmattan: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Kurale: {
    variants: ['regular'],
    category: 'serif',
  },
  Tillana: {
    variants: ['regular', '500', '600', '700', '800'],
    category: 'handwriting',
  },
  'Cormorant SC': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'serif',
  },
  Farro: {
    variants: ['300', 'regular', '500', '700'],
    category: 'sans-serif',
  },
  'David Libre': {
    variants: ['regular', '500', '700'],
    category: 'serif',
  },
  'Proza Libre': {
    variants: [
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
    ],
    category: 'sans-serif',
  },
  'Fanwood Text': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  Poly: {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  'Goudy Bookletter 1911': {
    variants: ['regular'],
    category: 'serif',
  },
  'Noto Sans Kannada': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Gurajada: {
    variants: ['regular'],
    category: 'serif',
  },
  Gabriela: {
    variants: ['regular'],
    category: 'serif',
  },
  'Scope One': {
    variants: ['regular'],
    category: 'serif',
  },
  'Noto Sans Hebrew': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'B612 Mono': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'monospace',
  },
  Recursive: {
    variants: ['300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Vesper Libre': {
    variants: ['regular', '500', '700', '900'],
    category: 'serif',
  },
  'Waiting for the Sunrise': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'IM Fell Double Pica': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  Buenard: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  KoHo: {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'sans-serif',
  },
  'Sue Ellen Francisco': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Sarpanch: {
    variants: ['regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Bengali': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Fahkwang: {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'sans-serif',
  },
  Anaheim: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Cormorant Upright': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'serif',
  },
  Arya: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Mallanna: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Zeyada: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Inknut Antiqua': {
    variants: ['300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  Inder: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Oriya': {
    variants: ['100', 'regular', '700', '900'],
    category: 'sans-serif',
  },
  Kadwa: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  Meddon: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'ZCOOL XiaoWei': {
    variants: ['regular'],
    category: 'serif',
  },
  'Noto Sans Gujarati': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Cambo: {
    variants: ['regular'],
    category: 'serif',
  },
  'Share Tech': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Andika: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Gurmukhi': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Mansalva: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Caladea: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'Rouge Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Readex Pro': {
    variants: ['200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Give You Glory': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Patrick Hand SC': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Kufam: {
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Della Respira': {
    variants: ['regular'],
    category: 'serif',
  },
  'Major Mono Display': {
    variants: ['regular'],
    category: 'monospace',
  },
  Fondamento: {
    variants: ['regular', 'italic'],
    category: 'handwriting',
  },
  Montez: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'DM Mono': {
    variants: ['300', '300italic', 'regular', 'italic', '500', '500italic'],
    category: 'monospace',
  },
  Numans: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Antic Didone': {
    variants: ['regular'],
    category: 'serif',
  },
  'Ma Shan Zheng': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Vollkorn SC': {
    variants: ['regular', '600', '700', '900'],
    category: 'serif',
  },
  'Euphoria Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Roboto Serif': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Geo: {
    variants: ['regular', 'italic'],
    category: 'sans-serif',
  },
  Italiana: {
    variants: ['regular'],
    category: 'serif',
  },
  'Sedgwick Ave': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Mouse Memoirs': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Trykker: {
    variants: ['regular'],
    category: 'serif',
  },
  'Tenali Ramakrishna': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Clicker Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Federo: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Source Serif 4': {
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Ledger: {
    variants: ['regular'],
    category: 'serif',
  },
  'Doppio One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Lekton: {
    variants: ['regular', 'italic', '700'],
    category: 'sans-serif',
  },
  'Fjord One': {
    variants: ['regular'],
    category: 'serif',
  },
  B612: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'Kiwi Maru': {
    variants: ['300', 'regular', '500'],
    category: 'serif',
  },
  'Encode Sans Expanded': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Dokdo: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Aref Ruqaa': {
    variants: ['regular', '700'],
    category: 'serif',
  },
  Orienta: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'RocknRoll One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Wendy One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Mukta Mahee': {
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  Balthazar: {
    variants: ['regular'],
    category: 'serif',
  },
  'Aguafina Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Serif Bengali': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Bilbo Swash Caps': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Newsreader: {
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
    category: 'serif',
  },
  Tienne: {
    variants: ['regular', '700', '900'],
    category: 'serif',
  },
  Timmana: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Crafty Girls': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Montserrat Subrayada': {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  'Brygada 1918': {
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    category: 'serif',
  },
  'Port Lligat Slab': {
    variants: ['regular'],
    category: 'serif',
  },
  'Walter Turncoat': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Notable: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Yusei Magic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Carrois Gothic SC': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Shalimar: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Montaga: {
    variants: ['regular'],
    category: 'serif',
  },
  Asul: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  'Mochiy Pop One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Lexend Zetta': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Just Me Again Down Here': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Headland One': {
    variants: ['regular'],
    category: 'serif',
  },
  'Loved by the King': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Wire One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Meera Inimai': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Solway: {
    variants: ['300', 'regular', '500', '700', '800'],
    category: 'serif',
  },
  'Port Lligat Sans': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Chau Philomene One': {
    variants: ['regular', 'italic'],
    category: 'sans-serif',
  },
  'Style Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Over the Rainbow': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Glory: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
    category: 'sans-serif',
  },
  'Bubbler One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Praise: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Delius Unicase': {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  Artifika: {
    variants: ['regular'],
    category: 'serif',
  },
  'Zen Kaku Gothic New': {
    variants: ['300', 'regular', '500', '700', '900'],
    category: 'sans-serif',
  },
  Kodchasan: {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'sans-serif',
  },
  'Noto Sans Sinhala': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Delius Swash Caps': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Lexend Exa': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Mako: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Gafata: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'The Girl Next Door': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Bilbo: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Shippori Mincho B1': {
    variants: ['regular', '500', '600', '700', '800'],
    category: 'serif',
  },
  DotGothic16: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Strait: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Almendra: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  Imprima: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'League Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Charmonman: {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  Petrona: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  Mina: {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  Gaegu: {
    variants: ['300', 'regular', '700'],
    category: 'handwriting',
  },
  Vibur: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Tauri: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Englebert: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Nastaliq Urdu': {
    variants: ['regular', '700'],
    category: 'serif',
  },
  Pavanam: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Cormorant Unicase': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'serif',
  },
  Fresca: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Song Myung': {
    variants: ['regular'],
    category: 'serif',
  },
  'Encode Sans Semi Expanded': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Cantora One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Zen Maru Gothic': {
    variants: ['300', 'regular', '500', '700', '900'],
    category: 'sans-serif',
  },
  Stoke: {
    variants: ['300', 'regular'],
    category: 'serif',
  },
  Engagement: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Stylish: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Asar: {
    variants: ['regular'],
    category: 'serif',
  },
  'Zen Kaku Gothic Antique': {
    variants: ['300', 'regular', '500', '700', '900'],
    category: 'sans-serif',
  },
  'Denk One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Puritan: {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'Gamja Flower': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Ibarra Real Nova': {
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    category: 'serif',
  },
  'IM Fell French Canon': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  Manuale: {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
    category: 'serif',
  },
  Allison: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Tomorrow: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'sans-serif',
  },
  'Libre Caslon Display': {
    variants: ['regular'],
    category: 'serif',
  },
  Gayathri: {
    variants: ['100', 'regular', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Mono': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'monospace',
  },
  Habibi: {
    variants: ['regular'],
    category: 'serif',
  },
  Sumana: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  'East Sea Dokdo': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Kulim Park': {
    variants: [
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    category: 'sans-serif',
  },
  Bayon: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Julee: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Zen Antique': {
    variants: ['regular'],
    category: 'serif',
  },
  'Sulphur Point': {
    variants: ['300', 'regular', '700'],
    category: 'sans-serif',
  },
  Prociono: {
    variants: ['regular'],
    category: 'serif',
  },
  'Lovers Quarrel': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Text Me One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Ramaraja: {
    variants: ['regular'],
    category: 'serif',
  },
  Rosarivo: {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  Dekko: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Rationale: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Zen Old Mincho': {
    variants: ['regular', '700', '900'],
    category: 'serif',
  },
  Nokora: {
    variants: ['100', '300', 'regular', '700', '900'],
    category: 'sans-serif',
  },
  Besley: {
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  'Donegal One': {
    variants: ['regular'],
    category: 'serif',
  },
  Piazzolla: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  'IM Fell French Canon SC': {
    variants: ['regular'],
    category: 'serif',
  },
  'Rum Raisin': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Ruluko: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Shanti: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Gotu: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Varta: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  WindSong: {
    variants: ['regular', '500'],
    category: 'handwriting',
  },
  'Inria Serif': {
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
    category: 'serif',
  },
  'Kotta One': {
    variants: ['regular'],
    category: 'serif',
  },
  'IM Fell Great Primer': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  'Swanky and Moo Moo': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Cagliostro: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Fenix: {
    variants: ['regular'],
    category: 'serif',
  },
  Condiment: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Sree Krushnadevaraya': {
    variants: ['regular'],
    category: 'serif',
  },
  'Hachi Maru Pop': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Spline Sans': {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Rhodium Libre': {
    variants: ['regular'],
    category: 'serif',
  },
  Chilanka: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Inika: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  'Kaisei Tokumin': {
    variants: ['regular', '500', '700', '800'],
    category: 'serif',
  },
  Comforter: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Kantumruy: {
    variants: ['300', 'regular', '700'],
    category: 'sans-serif',
  },
  'Lakki Reddy': {
    variants: ['regular'],
    category: 'handwriting',
  },
  MonteCarlo: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Azeret Mono': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'monospace',
  },
  Stalemate: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Ruthie: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Serif Malayalam': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  Chathura: {
    variants: ['100', '300', 'regular', '700', '800'],
    category: 'sans-serif',
  },
  'Andika New Basic': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Sahitya: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  'IM Fell DW Pica SC': {
    variants: ['regular'],
    category: 'serif',
  },
  'Noto Sans Georgian': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Kavivanar: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Marko One': {
    variants: ['regular'],
    category: 'serif',
  },
  'Oooh Baby': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Sura: {
    variants: ['regular', '700'],
    category: 'serif',
  },
  'Mochiy Pop P One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Jomolhari: {
    variants: ['regular'],
    category: 'serif',
  },
  Dorsa: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Junge: {
    variants: ['regular'],
    category: 'serif',
  },
  'Eagle Lake': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Meie Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Shippori Antique': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Dongle: {
    variants: ['300', 'regular', '700'],
    category: 'sans-serif',
  },
  Felipa: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Andada Pro': {
    variants: [
      'regular',
      '500',
      '600',
      '700',
      '800',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
    ],
    category: 'serif',
  },
  'IBM Plex Sans Thai': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Mrs Sheppards': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'IBM Plex Sans KR': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Linden Hill': {
    variants: ['regular', 'italic'],
    category: 'serif',
  },
  'Bona Nova': {
    variants: ['regular', 'italic', '700'],
    category: 'serif',
  },
  Molle: {
    variants: ['italic'],
    category: 'handwriting',
  },
  Stick: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'GFS Neohellenic': {
    variants: ['regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  'Zen Kurenaido': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Inria Sans': {
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
    category: 'sans-serif',
  },
  Galdeano: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Lexend Mega': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Beth Ellen': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Syne Mono': {
    variants: ['regular'],
    category: 'monospace',
  },
  Romanesco: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Devonshire: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Shippori Antique B1': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Peddana: {
    variants: ['regular'],
    category: 'serif',
  },
  Festive: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Lexend Giga': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'IBM Plex Sans Arabic': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Gowun Batang': {
    variants: ['regular', '700'],
    category: 'serif',
  },
  'Princess Sofia': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Ephesis: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'IBM Plex Sans Thai Looped': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Alumni Sans': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Grenze: {
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    category: 'serif',
  },
  'Montagu Slab': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'serif',
  },
  'Noto Sans Armenian': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Jim Nightshade': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Almendra SC': {
    variants: ['regular'],
    category: 'serif',
  },
  Snippet: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Hina Mincho': {
    variants: ['regular'],
    category: 'serif',
  },
  Gupter: {
    variants: ['regular', '500', '700'],
    category: 'serif',
  },
  'Gemunu Libre': {
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  Truculenta: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Kaisei Opti': {
    variants: ['regular', '500', '700'],
    category: 'serif',
  },
  'Odor Mean Chey': {
    variants: ['regular'],
    category: 'serif',
  },
  'Klee One': {
    variants: ['regular', '600'],
    category: 'handwriting',
  },
  'Noto Sans Symbols': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Myanmar': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Licorice: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Miss Fajardose': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Kaisei Decol': {
    variants: ['regular', '500', '700'],
    category: 'serif',
  },
  'Otomanopee One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Dr Sugiyama': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Jacques Francois': {
    variants: ['regular'],
    category: 'serif',
  },
  'IM Fell Great Primer SC': {
    variants: ['regular'],
    category: 'serif',
  },
  'Liu Jian Mao Cao': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Gwendolyn: {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  'Scheherazade New': {
    variants: ['regular', '700'],
    category: 'serif',
  },
  Yomogi: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Sedgwick Ave Display': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Hahmlet: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Butterfly Kids': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Mr Bedfort': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Preahvihear: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Zhi Mang Xing': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Vujahday Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Qwitcher Grypen': {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  Bonbon: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'IM Fell Double Pica SC': {
    variants: ['regular'],
    category: 'serif',
  },
  'Kaisei HarunoUmi': {
    variants: ['regular', '500', '700'],
    category: 'serif',
  },
  Benne: {
    variants: ['regular'],
    category: 'serif',
  },
  'M PLUS 2': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Gidugu: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Sunshiney: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Texturina: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  Fuggles: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Sans Gothic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Nerko One': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Birthstone Bounce': {
    variants: ['regular', '500'],
    category: 'handwriting',
  },
  'Lexend Tera': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Black And White Picture': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Yaldevi: {
    variants: ['200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Mea Culpa': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Seymour One': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Carattere: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Suwannaphum: {
    variants: ['100', '300', 'regular', '700', '900'],
    category: 'serif',
  },
  Mohave: {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
    ],
    category: 'sans-serif',
  },
  'Noto Sans Math': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Long Cang': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'BioRhyme Expanded': {
    variants: ['200', '300', 'regular', '700', '800'],
    category: 'serif',
  },
  'Noto Serif Kannada': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Gowun Dodum': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Ruge Boogie': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'The Nautigal': {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  Murecho: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Dhurjati: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Neonderthaw: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Twinkle Star': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Birthstone: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Serif Tamil': {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'serif',
  },
  'New Tegomin': {
    variants: ['regular'],
    category: 'serif',
  },
  'M PLUS 1': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Serif Thai': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  Suravaram: {
    variants: ['regular'],
    category: 'serif',
  },
  'Stick No Bills': {
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  Ballet: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Encode Sans SC': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Tamil Supplement': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Corinthia: {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  'Fuzzy Bubbles': {
    variants: ['regular', '700'],
    category: 'handwriting',
  },
  'Zen Antique Soft': {
    variants: ['regular'],
    category: 'serif',
  },
  'Bonheur Royale': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Imbue: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  Smooch: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Lexend Peta': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Qahiri: {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Estonia: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Serif Sinhala': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  Waterfall: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Imperial Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Xanh Mono': {
    variants: ['regular', 'italic'],
    category: 'monospace',
  },
  Genos: {
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    category: 'sans-serif',
  },
  Petemoss: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Luxurious Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Moon Dance': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Yuji Syuku': {
    variants: ['regular'],
    category: 'serif',
  },
  Hurricane: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Grechen Fuemen': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Trispace: {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800'],
    category: 'sans-serif',
  },
  'Noto Serif Devanagari': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Sans Egyptian Hieroglyphs': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Love Light': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Meow Script': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'M PLUS 1 Code': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Are You Serious': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Red Hat Mono': {
    variants: [
      '300',
      'regular',
      '500',
      '600',
      '700',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
    ],
    category: 'monospace',
  },
  'Island Moments': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Ole: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Inspiration: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Kolker Brush': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Sans Tai Viet': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Fleur De Leah': {
    variants: ['regular'],
    category: 'handwriting',
  },
  Cherish: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Serif Armenian': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Sans Symbols 2': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Kings: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Serif Georgian': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Grey Qo': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Sans Lao': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Uchen: {
    variants: ['regular'],
    category: 'serif',
  },
  'Yuji Boku': {
    variants: ['regular'],
    category: 'serif',
  },
  'Passions Conflict': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'IBM Plex Sans Hebrew': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Sassy Frass': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'IBM Plex Sans Devanagari': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Puppies Play': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Yuji Mai': {
    variants: ['regular'],
    category: 'serif',
  },
  'Noto Sans Khmer': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  Caramel: {
    variants: ['regular'],
    category: 'handwriting',
  },
  Explora: {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Sans Deseret': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Serif Ethiopic': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Sans Javanese': {
    variants: ['regular', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Tai Tham': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Coptic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'M PLUS Code Latin': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Serif Telugu': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Yuji Hentaigana Akebono': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Yuji Hentaigana Akari': {
    variants: ['regular'],
    category: 'handwriting',
  },
  'Noto Serif Gujarati': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Sans Thai Looped': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Brahmi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Serif Khmer': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Sans Cherokee': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Tagalog': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Imperial Aramaic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Ol Chiki': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Serif Lao': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Sans Multani': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Bamum': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Thaana': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Saurashtra': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Serif Hebrew': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Sans Cham': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Old Italic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Hanunoo': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Old Turkic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Rashi Hebrew': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Music': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Serif Myanmar': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Sans Carian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Mongolian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Serif Gurmukhi': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Serif Tibetan': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'serif',
  },
  'Noto Sans Canadian Aboriginal': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Sora Sompeng': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Anatolian Hieroglyphs': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Psalter Pahlavi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Meetei Mayek': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Lisu': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Adlam': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Balinese': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Serif Nyiakeng Puachue Hmong': {
    variants: ['regular', '500', '600', '700'],
    category: 'serif',
  },
  'Noto Sans Adlam Unjoined': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Serif Yezidi': {
    variants: ['regular', '500', '600', '700'],
    category: 'serif',
  },
  'Noto Sans Old Hungarian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Medefaidrin': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Tifinagh': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Kharoshthi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Yi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Sundanese': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Cypriot': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Tai Le': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Hanifi Rohingya': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Grantha': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Osmanya': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Inscriptional Pahlavi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Kayah Li': {
    variants: ['regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'Noto Sans Samaritan': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Syriac': {
    variants: ['100', 'regular', '900'],
    category: 'sans-serif',
  },
  'Noto Sans Inscriptional Parthian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans N Ko': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Pahawh Hmong': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Kaithi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Avestan': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Serif Balinese': {
    variants: ['regular'],
    category: 'serif',
  },
  'Noto Sans Indic Siyaq Numbers': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Old South Arabian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Cuneiform': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Old Persian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Serif Dogra': {
    variants: ['regular'],
    category: 'serif',
  },
  'Noto Sans Caucasian Albanian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Serif Grantha': {
    variants: ['regular'],
    category: 'serif',
  },
  'Noto Sans Batak': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Old North Arabian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans New Tai Lue': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Serif Ahom': {
    variants: ['regular'],
    category: 'serif',
  },
  'Noto Serif Tangut': {
    variants: ['regular'],
    category: 'serif',
  },
  'Noto Sans Newa': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Tirhuta': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Runic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Tagbanwa': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Phoenician': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Zanabazar Square': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Glagolitic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Buginese': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Masaram Gondi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Marchen': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Palmyrene': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Mayan Numerals': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Gunjala Gondi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Buhid': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Traditional Nushu': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Miao': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Mro': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Warang Citi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Limbu': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Syloti Nagri': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Mahajani': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Hatran': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Linear B': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Elbasan': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Lycian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Takri': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Pau Cin Hau': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Bassa Vah': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Rejang': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Elymaic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Ogham': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Sogdian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Duployan': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Old Permic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Modi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Khudawadi': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Manichaean': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Bhaiksuki': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Meroitic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Sharada': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Lepcha': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Vai': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Old Sogdian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Chakma': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Nushu': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Ugaritic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Osage': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Lydian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Phags Pa': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Soyombo': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Linear A': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Nabataean': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Khojki': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Wancho': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Siddham': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'Noto Sans Shavian': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  Fredoka: {
    variants: ['300', 'regular', '500', '600', '700'],
    category: 'sans-serif',
  },
  'League Gothic': {
    variants: ['regular'],
    category: 'sans-serif',
  },
  'League Spartan': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
  'Smooch Sans': {
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    category: 'sans-serif',
  },
};

export default detail;
