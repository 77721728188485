import _ from 'lodash';
import countryToCurrency from 'country-to-currency';
import fetcher from './fetcher';

const key = process.env.FIXER_FX_API_KEY;

export async function fxGetRates({ from, to }: { from: string; to?: string[] }) {
  let url = `https://data.fixer.io/api/latest?access_key=${key}&base=${from || 'USD'}`;

  if (to?.length) {
    url += `&symbols=${to.join(',')}`;
  }

  const response = await fetcher(url, {
    method: 'GET',
  });

  if (response.errors) {
    throw new Error(`Failed to fetch rates from Fixer API. ${response.errors[0].message}`);
  }

  return response.rates;
  /*
  {
    USD: 1,
    EUR: 0.838498,
    CAD: 1.262565,
    ...
  }
  */
}

export async function fxGetRate({ from, to }: { from: string; to: string }) {
  const rates = await fxGetRates({ from, to: [to] });

  return rates[to];
}

export async function fxConvert({
  from,
  to,
  amount,
}: {
  from: string;
  to: string;
  amount: number;
}) {
  const response = await fetcher(
    `https://data.fixer.io/api/convert?access_key=${key}&from=${from}&to=${to}&amount=${amount}`,
    {
      method: 'GET',
    }
  );

  if (response.errors) {
    throw new Error(`Failed to Convert from Fixer API. ${response.errors[0].message}`);
  }

  return {
    ...response.query,
    ...response.info,
    result: response.result,
  };

  /*
    {
      "timestamp": 1725430143,
      "rate": 0.738476,
      "from": "CAD",
      "to": "USD",
      "amount": 10.23
      "result": 7.554609
    }
  */
}

export function getCurrencyByCountryCode(countryCode: string) {
  return countryToCurrency[countryCode];
}

export async function convertByCountryCode({
  fromCountry,
  toCountry,
  amount,
}: {
  fromCountry: string;
  toCountry: string;
  amount: number;
}) {
  const from = await getCurrencyByCountryCode(fromCountry);
  const to = await getCurrencyByCountryCode(toCountry);

  return fxConvert({ from, to, amount });
}

export function getCurrencySymbol(currency: string) {
  return currencySymbols[currency] || '$';
}

export const currencySymbols = {
  USD: '$', // US Dollar
  CAD: 'CA$', // Canadian Dollar
  GBP: '£', // British Pound
  EUR: '€', // Euro
  AUD: 'A$', // Australian Dollar
  JPY: '¥', // Japanese Yen
  CNY: '¥', // Chinese Yuan
  INR: '₹', // Indian Rupee
  MXN: '$', // Mexican Peso
  BRL: 'R$', // Brazilian Real
  RUB: '₽', // Russian Ruble
  KRW: '₩', // South Korean Won
  CHF: 'CHF', // Swiss Franc
  SEK: 'kr', // Swedish Krona
  NOK: 'kr', // Norwegian Krone
  DKK: 'kr', // Danish Krone
  SGD: 'S$', // Singapore Dollar
  HKD: 'HK$', // Hong Kong Dollar
  NZD: 'NZ$', // New Zealand Dollar
  ZAR: 'R', // South African Rand
  MYR: 'RM', // Malaysian Ringgit
  THB: '฿', // Thai Baht
  AED: 'د.إ', // UAE Dirham
  SAR: '﷼', // Saudi Riyal
  ILS: '₪', // Israeli New Shekel
  EGP: '£', // Egyptian Pound
  TRY: '₺', // Turkish Lira
  PLN: 'zł', // Polish Zloty
  CZK: 'Kč', // Czech Koruna
  HUF: 'Ft', // Hungarian Forint
  PHP: '₱', // Philippine Peso
  VND: '₫', // Vietnamese Dong
  IDR: 'Rp', // Indonesian Rupiah
  PKR: '₨', // Pakistani Rupee
  NGN: '₦', // Nigerian Naira
  BDT: '৳', // Bangladeshi Taka
  KES: 'KSh', // Kenyan Shilling
  UAH: '₴', // Ukrainian Hryvnia
  CLP: '$', // Chilean Peso
  ARS: '$', // Argentine Peso
  KWD: 'د.ك', // Kuwaiti Dinar
  OMR: 'ر.ع.', // Omani Rial
  MAD: 'د.م.', // Moroccan Dirham
  LBP: 'ل.ل', // Lebanese Pound
  TWD: 'NT$', // New Taiwan Dollar
};

const currencyToCountry = _.invert(countryToCurrency);

export const currencyOptions = _.sortBy(Object.keys(currencyToCountry)).reduce((acc, currency) => {
  acc[`${currency} ${currencySymbols[currency] || '$'}`] = currencyToCountry[currency];
  return acc;
}, {} as Record<string, string>);

export async function getRateDataByCountry(fromCurrency: string) {
  const rates = await fxGetRates({ from: fromCurrency || 'USD' });

  return Object.entries(countryToCurrency).reduce((acc, [countryCode, currency]) => {
    acc[countryCode] = {
      currency,
      symbol: getCurrencySymbol(currency),
      rate: rates[currency] || 1,
    };
    return acc;
  }, {});
}
