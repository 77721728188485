import fontDetail from '../lib/font-detail';
import uniq from 'lodash/uniq';

function findSmaller(variants, target) {
  return [...variants].reverse().find(variant => variant <= target);
}

function findLarger(variants, target) {
  return variants.find(variant => variant >= target);
}

// Strategy based on https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#fallback_weights
function findWeight(variants, target) {
  let weight;

  // Between 400-500 inclusive
  if (target >= 400 && target <= 500) {
    weight = variants.find(variant => variant >= 400 && variant <= 500);
    // If not found, find value less than 400.
    if (!weight) weight = findSmaller(variants, 400);
    // If not found, find value greater than 500.
    if (!weight) weight = findLarger(variants, 500);
    return weight;
  }

  // Smaller than 400
  if (target < 400) {
    // Find value less than target.
    weight = findSmaller(variants, target);
    // If not found, find value greater than target.
    if (!weight) weight = findLarger(variants, target);
    return weight;
  }

  // Larger than 500
  // Find value greater than target
  weight = findLarger(variants, target);
  // If not found, find value less than target
  if (!weight) weight = findSmaller(variants, target);
  return weight;
}

// Aims to return:
// {
//   weights: { normal: '400', bold: '700', black: '900' },
//   italic: { normal: '400italic', bold: '700italic', black: '900italic' },
// },
// but will adjust the values based on which variants are available.
//
// Always returns normal, bold, and black keys.
//
// `variants` is from utils/font-details. It is assumed to be in order
// from lowest to highest. Values are font weight numbers like "100".
// The string "regular" cooresponds to 400. Italics are represented
// like "100italic", or just "italic" cooresponding to the regular variant.
function buildWeights(variants) {
  // Convert "regular" to 400 and "italic" to 400italic
  const normalized = variants.map(el => {
    if (el === 'regular') return 400;
    if (el === 'italic') return '400italic';
    if (!el.includes('italic')) return parseInt(el);
    return el;
  });
  // Filter out italic variants
  const base = normalized.filter(el => typeof el !== 'string');

  const weights = {
    normal: findWeight(base, 400),
    bold: findWeight(base, 700),
    black: findWeight(base, 900),
  };

  const italic = {
    hasNormal: !!normalized.find(variant => variant === `${weights.normal}italic`),
    hasBold: !!normalized.find(variant => variant === `${weights.bold}italic`),
    hasBlack: !!normalized.find(variant => variant === `${weights.black}italic`),
  };

  return { weights, italic };
}

// Recursively determine all heading and text font families.
function getPageFonts(pageData) {
  const headingFonts = [];
  const textFonts = [];

  (function getFonts(collection) {
    if (!collection || typeof collection !== 'object') return;
    if (Array.isArray(collection)) collection.forEach(getFonts);

    if (collection.headingFont) headingFonts.push(collection.headingFont.family);
    if (collection.textFont) textFonts.push(collection.textFont.family);
    if (collection.itemHeadingFont) headingFonts.push(collection.itemHeadingFont.family);
    if (collection.itemTextFont) textFonts.push(collection.itemTextFont.family);

    Object.keys(collection).forEach(key => getFonts(collection[key]));
  })(pageData);

  return { headingFonts: uniq(headingFonts), textFonts: uniq(textFonts) };
}

// This is meant to be run in server-side code to prepare:
// - font loading query string
// - fontFamily strings passed to the app to be used in css
// - heading/text weights passed to the app to be used as css variables
export function buildFonts(directusPageData) {
  // Get all heading and text fonts used in the page
  const { headingFonts, textFonts } = getPageFonts(directusPageData);
  const allFonts = uniq([...headingFonts, ...textFonts]);

  // Build weights for each unique font
  const weights = allFonts.reduce((acc, font) => {
    const detail = fontDetail[font];
    if (!detail) throw new Error(`Details for font family not found: ${font}`);
    return {
      ...acc,
      [font]: buildWeights(detail.variants),
    };
  }, {});

  const fontQueryStrings = textFonts.map(font => {
    const fontWeights = weights[font];
    const variants = [
      `0,${fontWeights.weights.normal}`,
      `0,${fontWeights.weights.bold}`,
      `0,${fontWeights.weights.black}`,
    ];
    // Only provide italics for normal and bold weights of text fonts.
    if (fontWeights.italic.hasNormal) variants.push(`1,${fontWeights.weights.normal}`);
    if (fontWeights.italic.hasBold) variants.push(`1,${fontWeights.weights.bold}`);

    return `${font.replace(/ /g, '+')}:ital,wght@${uniq(variants).join(';')}`;
  });

  headingFonts
    // Ignore any fonts that appear at text fonts. We don't need to load them twice
    // and more variants will be loaded for the text font.
    .filter(font => !textFonts.includes(font))
    .forEach(font => {
      const fontWeights = weights[font];
      const variants = [
        `0,${fontWeights.weights.normal}`,
        `0,${fontWeights.weights.bold}`,
        `0,${fontWeights.weights.black}`,
      ];

      fontQueryStrings.push(`${font.replace(/ /g, '+')}:ital,wght@${uniq(variants).join(';')}`);
    });

  return {
    // Query string is build for https://fonts.googleapis.com/css (version 1)
    fontLoadQueryString: fontQueryStrings.join('&family='),
    fonts: allFonts.reduce((acc, font) => {
      const { category } = fontDetail[font];
      return {
        ...acc,
        [font]: {
          fontFamily: `"${font}", ${category}`,
          weights: weights[font].weights,
        },
      };
    }, {}),
  };
}
