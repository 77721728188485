export const getDefaultRevalidationTime = () => {
  return process.env.DEFAULT_REVALIDATION_TIME
    ? parseInt(process.env.DEFAULT_REVALIDATION_TIME)
    : 60;
};

export const getContentPageRevalidationTime = () => {
  return process.env.CONTENT_PAGE_REVALIDATION_TIME
    ? parseInt(process.env.CONTENT_PAGE_REVALIDATION_TIME)
    : 60;
};
