import { FunnelPageType, FooterLinks } from 'constant';
import { funnelDataPropType } from 'utils/prop-types';
import fetcher from 'utils/fetcher';
import {
  buildFunnelPath,
  buildFunnelPageUrl,
  buildApiBaseUrl,
  buildFunnelPagePath,
} from 'utils/url';
import { buildFonts } from 'utils/font';
import { getRateDataByCountry } from 'utils/fx';
import {
  getPageData,
  getProductsData,
  getDirectusFileUrl,
  getPolicyPageData,
  directusPageTypeMap,
} from 'utils/directus';
import FetchError from 'utils/fetch-error';
import { parsePrice } from 'utils/price';
import { getAdminOfferImageUrl } from 'utils/velocity-admin';
import { createMockOffers } from 'utils/mock';
import { getDefaultRevalidationTime } from 'utils/config';

import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import rehypeStringify from 'rehype-stringify';
import dompurify from 'isomorphic-dompurify';

const formatMarkdown = text => {
  if (!text) {
    return null;
  }

  const sanitized = dompurify.sanitize(text);
  const processed = unified()
    .use(remarkParse)
    .use(remarkRehype, { allowDangerousHtml: true }) // Pass raw HTML strings through.
    .use(rehypeStringify, { allowDangerousHtml: true }) // Serialize the raw HTML strings
    .processSync(sanitized);
  return String(processed);
};

const formatAccordion = section => {
  section.item.formattedText = formatMarkdown(section.item.text);
  section.item.items = section.item.items.map(ai => {
    ai.item.contents = ai.item.contents.map(aic => {
      if (aic.item.type === 'text') {
        aic.item.formattedText = formatMarkdown(aic.item.text);
      }

      return aic;
    });
    return ai;
  });
};

const formatValueProposition = section => {
  section.item.formattedText = formatMarkdown(section.item.text);
  section.item.items = section.item.items.map(ai => {
    ai.item.bullets = ai.item.bullets.map(aic => {
      aic.item.formattedText = formatMarkdown(aic.item.text);
      return aic;
    });
    return ai;
  });
};

const formatAutoplayVideo = section => {
  section.item.formattedText = formatMarkdown(section.item.text);
  section.item.items = section.item.items.map(ai => {
    ai.item.formattedText = formatMarkdown(ai.item.text);
    return ai;
  });
};

const formatBulletList = section => {
  section.item.formattedText = formatMarkdown(section.item.text);
  section.item.bullets = section.item.bullets.map(b => {
    b.item.formattedText = formatMarkdown(b.item.text);
    return b;
  });
};

export const buildFullyDynamicGetStaticProps = ({ pageDataQuery, pageType }) => {
  return async function getStaticProps(context) {
    try {
      const { params, preview = null } = context;

      const { path, funnelVariantId } = params;

      const { data: funnelVariantData } = await fetcher(
        `${process.env.VELOCITY_ADMIN_URL}/api/public/funnel-variants/${funnelVariantId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.VELOCITY_ADMIN_API_KEY}`,
          },
        }
      );
      const { pages, funnel, useLocalCurrency, upsellSteps } = funnelVariantData;
      const country = useLocalCurrency ? context.req?.headers?.['x-country'] || 'US' : 'US';

      console.log({
        log: 'Building a funnel page with localized currency',
        useLocalCurrency,
        funnelVariantId,
        context: JSON.stringify(context),
        headers: JSON.stringify(context.req?.headers),
        ip_country: context.req?.headers?.['x-country'],
        country,
      });

      const offers = pages.reduce((prevOffers, page) => {
        if (page.type === FunnelPageType.SALES_PAGE) {
          return prevOffers.concat(page.offers);
        }

        return prevOffers;
      }, []);

      const { name: funnelName } = funnel;
      const funnelPage = pages.find(page => page.path.startsWith(path) && page.type === pageType);

      if (!funnelPage) {
        throw new Error(
          `Requested page (${pageType}: ${path}) does not exist for the funnel variant: ${funnelVariantId}`
        );
      }

      const { directusPageId } = funnelPage;
      const pageData = await getPageData({
        id: parseInt(directusPageId),
        pageType: directusPageTypeMap[pageType],
        pageDataQuery,
      });

      pageData.sections = pageData.sections.map(section => {
        if (section.collection === 'TwoColumn') {
          section.item.rightColumn = section.item.rightColumn.map(sectionItem => {
            if (sectionItem.collection === 'HeadingText') {
              sectionItem.item.formattedText = formatMarkdown(sectionItem.item.text);
            } else if (sectionItem.collection === 'BulletList') {
              formatBulletList(sectionItem);
            } else if (sectionItem.collection === 'Callout') {
              sectionItem.item.formattedText = formatMarkdown(sectionItem.item.text);
            } else if (sectionItem.collection === 'AutoplayVideo') {
              formatAutoplayVideo(sectionItem);
            } else if (sectionItem.collection === 'Accordion') {
              formatAccordion(sectionItem);
            } else if (sectionItem.collection === 'FinalCta') {
              sectionItem.item.formattedText = formatMarkdown(sectionItem.item.text);
            }

            return sectionItem;
          });
        } else if (section.collection === 'Accordion') {
          formatAccordion(section);
        } else if (section.collection === 'AutoplayVideo') {
          formatAutoplayVideo(section);
        } else if (section.collection === 'ValueProposition') {
          formatValueProposition(section);
        } else if (section.collection === 'FinalCta') {
          section.item.formattedText = formatMarkdown(section.item.text);
        }

        return section;
      });

      if (pageData.banners) {
        pageData.banners = pageData.banners.map(banner => {
          if (banner.collection === 'StickyBanner') {
            banner.item.formattedText = formatMarkdown(banner.item.text);
          }

          return banner;
        });
      }

      const fontData = buildFonts(pageData);

      let offerData;
      if (!offers.length) {
        // Use mock offer data
        offerData = {
          data: createMockOffers(10),
          mock: true,
        };
      } else {
        offerData = {
          data: offers,
        };
      }

      // Use item brand name from the first offer. Note: this would need to be
      // updated if we start using offers from different merchants on a sales page.
      const merchantName = offerData.data[0]?.store?.brand?.name ?? null;
      const currency = offerData.data[0]?.currency ?? 'USD';

      let fxRates = null;

      if (useLocalCurrency) {
        fxRates = await getRateDataByCountry(currency);
      }

      const isPaypalSupported = offerData.data.every(
        offer => offer?.store?.paypalOnboardingStatus === 'Completed'
      );

      const isAfterpaySupported = offerData.data.every(offer => {
        const supported = offer?.store?.afterpaySupported;
        const validPrice = parsePrice(offer?.price) >= 1 && parsePrice(offer?.price) <= 2000;
        return supported && validPrice;
      });

      const directusProductIds = offers.reduce((productIds, o) => {
        if (o.directusProductId) {
          productIds.push(parseInt(o.directusProductId));
        }

        return productIds;
      }, []);

      const productsData = await getProductsData({ productIds: directusProductIds });

      const {
        externalName,
        rootDomain,
        googleOptimizeId,
        googleTagManagerId,
        loadFromServerContainer,
        googleTagManagerAuthCode,
        googleTagManagerPreview,
        ga4MeasurementId,
        klaviyoPublicKey,
        supportEmail,
      } = funnel.brand;

      const apiBaseUrl = buildApiBaseUrl({ rootDomain });
      const canonicalUrl = buildFunnelPageUrl({ rootDomain, funnelPage, fid: funnelVariantId });

      const firstUpsellStep = upsellSteps?.[0];
      const firstUpsellPageId = firstUpsellStep?.upsellPage;
      const firstUpsellPage = pages.find(({ id }) => id === firstUpsellPageId);
      const upsellUrl = firstUpsellPage?.path
        ? buildFunnelPath(buildFunnelPagePath(firstUpsellPage), funnelVariantId)
        : null;

      // Look up policy page content for modals.
      const policyContent = {};
      if (pageType === FunnelPageType.CHECKOUT_PAGE) {
        await Promise.all(
          [FunnelPageType.TERMS_AND_CONDITIONS_PAGE, FunnelPageType.PRIVACY_POLICY_PAGE].map(
            async type => {
              const page = pages.find(el => el.type === type);
              if (!page) return;
              const { title, content } = await getPolicyPageData({
                id: parseInt(page.directusPageId),
              });
              policyContent[type] = { title, content };
            }
          )
        );
      }

      const funnelData = {
        shopCategory: [],
        ...fontData,
        // TODO: offerData could now be combined with offersConfig at this point.
        // - offerData is no longer signed, so does not need to remain immutable.
        // - combining would allow us to simplify our CTA components that merge offerData and offersConfig at runtime.
        // - check where offersConfig is needed, as it may still be used for events on the checkout pages.
        // - another option would be to split this funnel-page file into a separate version for checkout pages.
        offerData,
        offersConfig: offers.map(offer => {
          const directusProductData = productsData?.find(p => p.id === offer.directusProductId);
          const directusProductImage = directusProductData?.image;
          const offerDataOffer = offerData.data.find(el => el.id === offer.id);
          const salesPage = pages.find(page => page.type === FunnelPageType.SALES_PAGE);

          return {
            ...offer,
            productUrl: buildFunnelPageUrl({
              rootDomain,
              funnelPage: salesPage,
              fid: funnelVariantId,
            }),
            image: directusProductImage
              ? getDirectusFileUrl(directusProductImage)
              : getAdminOfferImageUrl(offerDataOffer),
          };
        }),
        upsellUrl,
        funnelId: funnelVariantId,
        funnelPageId: funnelPage?.id,
        funnelName,
        merchantName,
        brandExternalName: externalName,
        rootDomain,
        apiBaseUrl,
        canonicalUrl,
        googleOptimizeId,
        googleTagManagerId,
        loadFromServerContainer,
        googleTagManagerAuthCode,
        googleTagManagerPreview,
        ga4MeasurementId,
        klaviyoPublicKey,
        supportEmail,
        links: {
          privacyPolicy: buildFunnelPath(FooterLinks.PRIVACY_POLICY, funnelVariantId),
          termsAndConditions: buildFunnelPath(FooterLinks.TERMS_AND_CONDITIONS, funnelVariantId),
          refundPolicy: buildFunnelPath(FooterLinks.REFUND_POLICY, funnelVariantId),
          contactUs: buildFunnelPath(FooterLinks.CONTACT_US, funnelVariantId),
          ourStory: buildFunnelPath(FooterLinks.OUR_STORY, funnelVariantId),
        },
        policyContent,
        isPaypalSupported,
        isAfterpaySupported,
        currency,
        fxRates,
        useLocalCurrency,
      };

      const checkoutPage = pages.find(page => page.type === 'CheckoutPage');

      if (checkoutPage) {
        funnelData.checkoutPath = buildFunnelPagePath(checkoutPage);
      }

      return {
        revalidate: getDefaultRevalidationTime(),
        props: {
          preview,
          funnelData,
          pageData,
        },
      };
    } catch (err) {
      if (err instanceof FetchError) {
        const { statusCode } = err;
        switch (statusCode) {
          case 404: {
            return {
              redirect: {
                destination: '/',
                permanent: false,
              },
            };
          }

          default: {
            throw err;
          }
        }
      }

      throw err;
    }
  };
};

export const buildGetStaticPaths = ({ funnelPageType, pageDataQuery }) => {
  return async function getStaticPaths() {
    const { data: funnelVariants } = await fetcher(
      `${process.env.VELOCITY_ADMIN_URL}/api/public/funnel-variants`,
      {
        headers: {
          Authorization: `Bearer ${process.env.VELOCITY_ADMIN_API_KEY}`,
        },
      }
    );

    const paths = await Promise.all(
      funnelVariants.flatMap(funnelVariant =>
        funnelVariant.pages
          .filter(page => page.type === funnelPageType)
          .map(async page => {
            const { path, directusPageId } = page;
            const paramData = { funnelVariantId: funnelVariant.id, path };
            if (funnelPageType === FunnelPageType.ARTICLE_PAGE) {
              const pageData = await getPageData({
                id: parseInt(directusPageId),
                pageType: directusPageTypeMap[page.type],
                pageDataQuery,
              });
              paramData.slug = pageData.category?.name;
              paramData.tag = pageData.tag?.name;
            }

            return {
              params: {
                ...paramData,
              },
            };
          })
      )
    );

    return { paths, fallback: 'blocking' };
  };
};

export const propTypes = {
  funnelData: funnelDataPropType,
};

export const defaultProps = {
  funnelData: null,
};
