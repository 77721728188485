import range from 'lodash/range';

// Create mock data to simulate offers from funnel variant lookup
export function createMockOffers(count) {
  return range(1, count).map(i => ({
    mock: true,
    id: `mock${i}`,
    name: `Mock offer ${i}`,
    price: `${i}0.00`,
    compareAtPrice: `${i}5.00`,
    storeProductQuantity: 1,
    maxQuantity: 10,
    store: {
      currency: 'USD',
      paypalOnboardingStatus: 'Completed',
      afterpaySupported: true,
      paypalMerchantId: process.env.UNCOIL_PAYPAL_MERCHANT_ID,
      brand: {
        name: 'Mock Brand',
      },
    },
  }));
}
